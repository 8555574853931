@import '../../Constants/variables.scss';

$angle: 60deg;

.topbar {
  color: white;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  padding-top: $size-small;
  padding-bottom: $size-small;
  padding-left: $size-normal;
  padding-right: $size-normal;
  display: flex;
  align-items: center;
}

.cpTitle {
  color: white !important;
  text-decoration: none !important;
  font-weight: 350;
  font-size: 22px;
}

.topbarContent {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 460px) {
    flex-direction: column;
  }
}

.leftPane {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 350;

  @media only screen and (max-width: 460px) {
    margin-bottom: 16px;
  }
}

.centerPane {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.rightPane {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.topBarMenuItemContainer {
  margin-right: 2px;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.topBarMenuItemContainer:last-child {
  margin-right: 0px;
}

.menuItemIcon {
  font-size: 1em;
}

.topBarCircle {
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.notificationBadge {
  position: absolute;
  top: 0px;
  right: 0px;
}

.avatar {
  // background-color: #f36f21;
  border-radius: 25px;
  //overflow: hidden;
  //background-image: url('../../Assets/goat.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: none;
  margin: 0px;
}

.menuHighlight {
  // background-color: lighten($primary-color, 5%);
  border-radius: 32px;
  padding: 4px;
}

.dropdownTopbar {
  top: 55px;
  @media only screen and (max-width: 460px) {
    position: fixed;
    top: 64px;
  }
}

.userInfo {
  border-bottom: 1px solid #f4f4f4;
  padding: $size-normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownHeader {
  border-bottom: 1px solid #f4f4f4;
  padding: $size-small;
  display: flex;
  flex-direction: row;
  font-size: 0.9em;
  font-weight: 500;
}


.dropdownMoreBox {
  display: flex;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
}

.dropdownMoreBox:hover {
  background: #f4f4f4;
}

.menuItemBox {
  border-bottom: 1px solid #f4f4f4;
}

//.menuItemBox:hover {
//  background-color: #f4f4f4;
//}

.menuItemBox:last-child {
  border-bottom: none;
}
