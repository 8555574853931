@import '../../Constants/variables.scss';

.videoParent {
    display: flex;
    flex-wrap: wrap;
}

.videoChild {
    flex-basis: 33.33333%;
    padding-bottom: 20px;
}