@import "../../Constants/variables.scss";

.card-component {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $color-white;
  border-radius: $size-smaller;
  padding: $size-normal;
  // margin-bottom: $size-normal;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  &.dark {
    background-color: rgb(85, 85, 85);
    color: white;
  }
}

.cardNoPadding {
  padding: 0px;
  overflow: hidden;
}