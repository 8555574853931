@import "../../Constants/variables.scss";

.modal-overlay {
  position: fixed;
  top: 0;
  z-index: 9999;
  right: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  //width: 100%;
  align-items: center;
  justify-content: center;
  background-color: $color-modal-overlay;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-content: center;
  align-items: center;
  //backdrop-filter: blur(.75px);
}

.modal-container::backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-card {
  height: fit-content;
}

.modal-container {
  min-width: 200px;
  width: 100%;
  position: relative;
  display: flex;

  .close-modal-button {
    position: absolute;
    cursor: pointer;
    align-self: flex-end;
    color: inherit
  }

  .close-modal-button:hover {
    color: lighten($color-red, 10%);
  }
}
