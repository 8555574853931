//$primary-color: #2d5e83;
$primary-color: #1671aa;
$primary-color-darker: darken($primary-color, 3%);
$primary-color-lighter: lighten($primary-color, 20%);
$color-background: #ecf0f5;
$color-form-label: #83878c;
$sidebar-color: #3d3d3d;
$sidebar-border-color: lighten($sidebar-color, 10%); // or 14
$sidebar-subitem-color: lighten($sidebar-color, 5%);

$text-color: #212529;

$accent-color: #f36f21;
$color-white: white;
$color-green: #28a745;
$color-red: #e81818;
$color-blue: #6690bf;
$color-aqua: #00c0ef;
$color-very-light-grey: #dee2e6;
$color-modal-overlay: rgba(0, 0, 0, 0.5);

$size-extra-large: 64px;
$size-larger: 48px;
$size-large: 32px;
$size-kinda-large: 24px;
$size-normal: 16px;
$size-small: 8px;
$size-smaller: 4px;
$size-very-small: 2px;

$top-bar-height: 84px;

:export {
  primaryColor: $primary-color;
  primaryColorDarker: $primary-color-darker;
  primaryColorLighter: $primary-color-lighter;
  textColor: $text-color;
  accentColor: $accent-color;
  colorGreen: $color-green;
  colorRed: $color-red;
  colorBlue: $color-blue;
  colorAqua: $color-aqua;
  colorVeryLightGrey: $color-very-light-grey;
  sidebarColor: $sidebar-color;
  sidebarBorderColor: $sidebar-border-color;
  sidebarSubItemColor: $sidebar-subitem-color;
}
