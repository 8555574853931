@import '../../Constants/variables.scss';

$sidebar-width: 52px;
$elements: 15;

.sidebar-buffer-expanded {
  min-width: 220px;
}

.sidebar {
  font-size: 0.9em;
  background-color: $sidebar-color;
  border-right: 0.5px solid $sidebar-border-color;
  z-index: 100;
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  text-align: start;
  white-space: nowrap;
  position: absolute;
  overflow-x: hidden;
  width: 0px;
  transition: width 0.5s ease-out;
  width: $sidebar-width;
  padding-top: $size-small;
  height: calc(100vh - 64px);
  overflow: auto;

  svg {
    transition: 0.2s;
  }
}

.sidebar-expanded {
  width: 220px;
}

.sidebar:hover {
  width: 220px;
}

.sidebar-item {
  height: 40px;
  padding-top: $size-small;
  padding-bottom: $size-small;
  padding-right: 12px;
  padding-left: 12px;
  align-items: center;
}

.sidebarItemBorderTop {
  border-top: 1px solid $sidebar-border-color;
}

.sidebarItemBottom {
  margin-top: auto;
}

.sidebar-item a {
  color: darken(white, 17%);
  text-decoration: none;
}

.sidebar-item-active {
  padding-left: $size-small;
}

.sidebar-item-active a {
  color: white;
}

.sidebar-item:hover a {
  color: white;
}

.sidebar-expanded .sidebar-text {
  opacity: 1;
}

.sidebar-text {
  flex: 1;
  opacity: 0;
  margin: 0;
  transition: opacity 0.2s ease-out;
}

.sidebar:hover .sidebar-text {
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .sidebar-buffer {
    display: none;
  }
  .sidebarHidden {
    width: 0px;
  }
}

@media only screen and (min-width: 600px) {
  .sidebar-buffer {
    display: flex;
  }
  .sidebar {
    width: 220px;
  }
  .sidebar-text {
    opacity: 1;
  }
}

.nonClickable {
  a {
    cursor: default;
  }
}

.nonClickable:hover {
  svg {
    color: white !important;
  }
}
