@import '../../Constants/variables.scss';

$sidebar-width: 52px;
$sidebar-color: #3d3d3d;

.background {
  background-color: $color-background;
  min-height: 100vh;
  flex: 1;
  flex-direction: column;

  &.dark {
    background-color: #192535;
    color: $color-white;
  }

  &.white {
    background-color: white;
  }
}

.content {
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 64px);
  padding-bottom: 16px;
}

.pageLayoutTitle {
  //padding: 8px 0px;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 8px;
}
