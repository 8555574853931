@import '../../Constants/variables.scss';

.dropdownComponent {
  color: $text-color;
  right: 0px;
  min-width: 250px;
  z-index: 999;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  //overflow: hidden;

  .dropdownMenuItem:hover {
    background-color: #f4f4f4;
  }

  @media only screen and (max-width: 460px) {
    position: fixed;
    left: 16px;
    right: 16px;
  }
}
