@import "../../Constants/variables.scss";

.label {
  align-items: center;
  justify-content: flex-end;
  text-align: end;
  flex: 1;
  display: flex;
  font-size: 0.8em;
  font-weight: 500;
  padding-right: $size-small;
  //color: $color-form-label;
  @media only screen and (max-width: 650px) {
    justify-content: flex-start;
    text-align: start;
  }
}

.formFieldContainer {
  display: flex;
  flex: 1;
  flex-direction: row;

  @media only screen and (max-width: 650px) {
    flex-direction: column;
  }
}

.field {
  flex: 3;
  display: flex;

  &.halfWidth {
    flex: 1
  }


  p {
    margin: 0px;
  }

  &.error {
    color: $color-red
  }
}

.button-container {
  margin-top: auto;
}

.delete-button {
  align-self: flex-start;
  margin-right: auto;
}

.cancel-button {
  margin-right: $size-small;
}

.numberInput {
  cursor: pointer;

  button {
    width: 46px;
    height: 46px;
    border-radius: 23px;
    margin-bottom: 8px;
  }

  div {
    div {
      height: 200px;
      overflow: auto;

      a {
        border-bottom: 1px solid #f4f4f4;
      }
    }
  }
}

//.tab-content,
//.tab-pane.active {
//  display: flex;
//  flex-direction: column;
//  flex: 1;
//}
//
//.tab-form {
//  display: flex;
//  flex: 1;
//  flex-direction: column;
//}
//.tab-container {
//  padding-top: $size-kinda-large;
//  display: flex;
//  flex: 1;
//  flex-direction: column;
//  height: 100%;
//  padding-right: $size-normal;
//  padding-left: $size-normal;
//}

.stagedFormContent{
  max-height: calc(100vh - 200px);
    overflow: auto;
}