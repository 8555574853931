@import '../../Constants/variables.scss';

.breadcrumb {
  background-color: unset;
  margin: 0;
  padding-left: 0px;
  padding-right: 0px;
  display: flex;

  //justify-content: flex-end;

}