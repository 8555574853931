@import '../../Constants/variables.scss';


$helper-bar-width: 400px;
$helper-bar-padding: 12px;

.helperBarContainer {
  overflow: hidden;
  width: 0;
  height: calc(100vh - 64px);
  position: absolute;
  top: 64px;
  right: 0;
  color: white;
  background-color: $sidebar-color;
  transition: width .3s ease-out;
  z-index: 9999;

  .helperBarContent {
    font-size: 0.9em;
    opacity: 0;
    transition: opacity 0.2s ease-out;
    padding: $helper-bar-padding;
    height: 100%;
    display: flex;
    flex-direction: column;

    .helperBarTitle {
      font-size: 1.2em;
      margin-bottom: 12px;
    }

    .helperBarItem {
      width: calc(400px - (12px * 2));
      white-space: initial;
      margin-bottom: 12px;

      .helperBarItemTitle {
        text-decoration: underline;
        margin-bottom: 12px;
      }

      .helperBarItemText {
        font-weight: 300;
      }
    }
  }

  &.helperBarContainerOpen {
    width: $helper-bar-width;
    overflow-y: auto;

    .helperBarContent {
      opacity: 1;
    }
  }
}