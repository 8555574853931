@import "../../Constants/variables.scss";

.menuItemNavLink a {
  text-decoration: none;
}

.menuItemIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-right: $size-small;
}

.menuItemRightIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}


.menuItemHoverHighlight:hover {
  background-color: #f4f4f4;
}

.menuItemBorderBottom {
  border-bottom: 1px solid #f4f4f4
}