@import "../../Constants/variables.scss";

.App {
  text-align: center;
}

.btn-primary,
.page-item.active .page-link {
  // background-color: $primary-color;
  background-color: transparent;
  border-color: transparent;
  z-index: 0;
}

.page-item .page-link {
  color: $primary-color;
}

.page-item.disabled .page-link {
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.btn-outline-primary {
  border-color: $primary-color;
  color: $primary-color;
}

.btn-outline-primary:hover {
  border-color: $primary-color;
  background-color: $primary-color;
}

.btn-primary:hover {
  border-color: lighten($primary-color, 10%);
  background-color: lighten($primary-color, 10%);
}

.nav-tabs a {
  color: lighten($primary-color, 15%);
}

.nav-tabs a:hover {
  color: lighten($primary-color, 10%);
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.justify-end {
  justify-content: flex-end;
}
