@import '../../Constants/variables.scss';

.dashboardSummaryUpperSection {
  padding: $size-small;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.dashboardSummaryLowerSection {
  padding: $size-normal;
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dashboardSummarySection {
  padding: $size-normal;
  //padding: 0px $size-small;
  flex: 1;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.dashboardSummaryTitle {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.25;
  display: flex;
  justify-content: center;
  flex: 1;
}

.dashboardSummarySubtitle {
  font-weight: 450;
}

.dashboardSummaryIcon {
  height: 40px;
  align-items: center;
  display: flex;
  margin-right: auto;
  padding-left: 8px;
  flex: 1;
}

.dashboardSummarySpacer {
  display: flex;
  flex: 1;
  margin-left: auto;
}

.dashboardSummaryBorderRight {
  border-right: 0.5px solid #d5d5d5;
}

.dashboardWidgetSmallIcon {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dashboardWidgetSmallText {
  flex-direction: column;
  margin-left: 8px;
  justify-content: center;
  display: flex;
}

.dashboardWidgetSmallQuanity {
  font-size: 0.9em;
}

.dashboardWidgetWithBarIconSection {
  display: flex;
  padding: 32px;
}

.dashboardWidgetWithBarContentSection {
  display: flex;
  flex: 1;
  color: white;
  padding: 16px;
  padding-top: 24px;
  flex-direction: column;
}

.dashboardWidgetWithBarText {
  margin-bottom: 24px;
}

.dashboardWidgetWithBarBarBackground {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  margin-bottom: 24px;
}

.dashboardWidgetWithBarBarFront {
  min-width: 0%;
  height: 2px;
  background-color: white;
  border-radius: 2px;
  transition: width 0.2s ease-out;
}

.notificationsWidgetTitle {
  //padding: 16px;
  //font-size: 20px;
  box-shadow: 0 0px 7px 0px #b8b8b8;
  z-index: 99;
  color: white;
}

.notificationsWidgetContent {
  padding: 8px;
  padding-bottom: 0;
  font-size: 1.2em;
  min-height: 300px;
  flex-direction: column;
  overflow: auto;
  flex: 1;
}

.notificationsWidgetMore {
  z-index: 999;
  //padding: 8px;
  box-shadow: 0 0px 7px 0px #b8b8b8;
}


.dashboardSummaryGridItemContainer {
  padding: 16px 16px;
  border-right: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .dashboardSummaryGridItemInner {
    flex-direction: row;
    align-items: center;
    display: flex;

    .dashboardSummaryGridItemIconContainer {
      padding: 8px;
      font-size: 20px;
      margin-right: 8px;
      border-radius: 8px;
      overflow: hidden;

      .dashboardSummaryGridItemIcon {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .dashboardSummaryGridText {
      display: flex;
      flex-direction: column;
    }
  }

  &:hover {
    .dashboardSummaryGridItemIconContainer {
      opacity: 0.7;
    }
  }
}