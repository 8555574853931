@import "src/Constants/variables";

.tabbedCard {
  //overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $color-white;
  border-radius: $size-smaller;

  //-webkit-box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.34);
  //-moz-box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.34);
  //box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.34);

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  &.dark {
    background-color: rgb(85, 85, 85);
    color: white;
  }
}

.cardTabActive {
  flex-direction: row;
  z-index: 99;
  opacity: 1;

  box-shadow: 0px 0px 7px -3px rgba(0, 0, 0, 0.34);

  span {
    font-weight: 500;
  }
}

.cardTabInactive {
  cursor: pointer;
  opacity: 0.6;
  //color: grey;
}

.cardTabDisabled {
  color: dimgrey;
  cursor: not-allowed;
}

.cardTabActive, .cardTabInactive, .cardTabDisabled {
  white-space: nowrap;
  padding-top: 24px;
  border-right: 1px solid #f4f4f4;
  width: 135px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  flex-direction: column;
  flex: 1;
  //overflow: hidden;
  transition: color 0.1s ease-in;
  transition: opacity 0.05s ease-in;

  span {
    transition: font-weight 0.05s ease-in;
    font-size: 0.9em;
  }

  &.cardTabErrored {
    color: $color-red !important;
  }
}

.cardTabActive:hover, .cardTabInactive:hover, .cardTabDisabled:hover {
  background-color: #f8f8f8;
}

.cardTabInactive:hover {
  opacity: 0.8;
  //background-color: #f8f8f8;
  //flex-direction: row;
  //z-index: 98;
  ////background: linear-gradient(0.6turn,
  ////        lighten($primary-color, 30%),
  ////        lighten($primary-color, 25%));
  //
  //-webkit-border-top-left-radius: $size-small;
  //-webkit-border-top-right-radius: $size-small;
  //-moz-border-radius-topleft: $size-small;
  //-moz-border-radius-topright: $size-small;
  //border-top-left-radius: $size-small;
  //border-top-right-radius: $size-small;
  ////color: $color-white;
  //color: $primary-color;


  //box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  //0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.cardTabMore {
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
  width: 150px;
  //margin-right: 0px
}

.cardTabDropdownItem {
  //padding: 8px;
  //border-bottom: 1px solid #eaeaea;
  font-size: 0.95em;
}

.tabPanel {
  //box-shadow: 0 0px 7px 0px #b8b8b8;
  //border-bottom: 0.25px solid #e0dfdf;

  //box-shadow: 0 0px 7px 0px #b8b8b8;

  -webkit-box-shadow: 0px 4px 6px -5px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0px 4px 6px -5px rgba(0, 0, 0, 0.34);
  box-shadow: 0px 4px 6px -5px rgba(0, 0, 0, 0.34);
  //border-bottom: 0.25px solid #e9e7e7;

  //-webkit-box-shadow: 0 8px 3px -7px #b8b8b8;
  //-moz-box-shadow: 0 8px 3px -7px #b8b8b8;
  //box-shadow: 0 8px 3px -7px #b8b8b8;

}

.tabbedCardContent {
  padding-top: 32px;
  padding-bottom: 16px;

  div {
    //max-width: none !important;
  }
}
