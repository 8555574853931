@import '../../Constants/variables.scss';

.successTextContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 64px;
  text-align: center;

  .successText {
    font-size: 80px;
    text-align: center;
    line-height: 1;
  }

  .successSecondaryText {
    font-size: 30px;
    line-height: 2;
  }

  .successLink {
    font-size: 22px;
    line-height: 1.5;
  }
}