.containerComponent {
  width: 100%;
  @media (min-width: 960px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media (min-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (max-width: 600px) {
    padding-left: 8px;
    padding-right: 8px;
  }
}
