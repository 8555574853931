@import "../../Constants/variables.scss";

.inputGroupNoMargin {
  margin: 0 !important;
}


.reactselect {
  div {
    transition: none !important;
  }
}

// Overriding default Material UI input styles
.MuiFormLabel-root {
  font-family: inherit !important;
}

.MuiInputBase-root {
  font-family: inherit !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $primary-color-darker !important
}

.MuiInput-underline:after {
  border-bottom: 2px solid $primary-color-darker !important;
}

.MuiIconButton-colorPrimary.Mui-checked {
  color: $primary-color !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $primary-color-darker !important;
}

.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0;
  margin-bottom: 0;
  margin-right: 0;
}

.MuiFormGroup-root.MuiFormGroup-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.MuiFilledInput-root {
  //background-color: lighten($primary-color, 55%) !important;
  background-color: rgba(lighten($primary-color, 55%), .4) !important;
}

.MuiStepIcon-root.Mui-error {
  color: #f44336 !important;
}

.MuiTypography-body1 {
  font-family: inherit !important
}

.MuiTypography-body2 {
  font-family: inherit !important
}

// end

.switch {
  span {
    span {
      cursor: pointer !important;
    }
  }
}

.dialPad {
  border-radius: 8px;
  overflow: hidden;
  max-width: 258px;
  background-color: #cdcdcd1c;
  padding: 8px;

  .dialPadKey {
    cursor: pointer;
    user-select: none;
    flex-direction: column;
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 6px;
    opacity: 0.75;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;


    &.disabled {
      color: lighten($text-color, 50%);
      cursor: not-allowed;

    }

    &.disabled:hover {
      color: lighten($text-color, 50%);

    }

    &.selected {
      opacity: 1;
      color: $accent-color
    }
  }

  .dialPadKey:hover {
    opacity: 1;

    // &.selected {
    //   color: $accent-color;
    // }
  }
}


.datePicker {
  .MuiPickersToolbar-toolbar,
  .MuiPickersDay-daySelected {
    background-color: $primary-color;
  }

  .calendarIcon {
    color: darken($primary-color-darker, 10%);
    padding-right: 12px;
    cursor: pointer;
  }

  [type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }
}

.timePicker {
  display: flex;

  .timeInputContainer {
    display: flex;
    align-items: center;
    flex-direction: row;

    .clockIcon {
      color: darken($primary-color-darker, 10%);
      padding-right: 12px;
      cursor: pointer;
    }

    .clockIcon:hover {
      color: $primary-color
    }

    &.timeInputContainerReversed {
      flex-direction: row-reverse;

      .clockIcon {
        padding-right: 0;
        padding-left: 12px;
      }
    }
  }

  .clockIcon {
    color: darken($primary-color-darker, 10%);
    padding-right: 12px;
    cursor: pointer;
  }

  .clockIcon:hover {
    color: $primary-color
  }

  input {
    width: 64px !important;
  }

  button {
    padding: 4px;
  }

  .MuiInput-root {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .MuiInputBase-input {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px) !important;
    padding: .375rem .75rem !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    box-sizing: inherit

  }

  &.small {
    input {
      width: 52px !important;
    }

    .MuiInputBase-input {
      font-size: 0.9em !important;
    }
  }

  .MuiInput-underline:after {
    display: none;
  }

  .MuiInput-underline:before {
    display: none;
  }
}

.line {
  width: 32px;
  height: 32px;
  // border-bottom: 1px solid $accent-color;
  -webkit-transform: translateY(-11px) translateX(11px) rotate(45deg);

  position: absolute;
}