@import '../../Constants/variables.scss';

.table-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding-bottom: $size-small;

  h3 {
    font-weight: 450;
    margin: 0px;
  }
}

@media only screen and (max-width: 900px) {
  .table-card {
    flex-direction: column;
    align-items: flex-start;
  }
  .tableTitle {
    margin-bottom: 16px;

  }
  .tableActions {
    width: 100%;

    .actionButtons {
      flex: 0 !important;
    }
  }
}

//.tableActions {
//  background-color: green;
//  display: flex;
//  flex-direction: row;
//}

@media only screen and (min-width: 800px) {
  .tableActions {
    display: flex;
    flex-direction: row;

    .actionButtons {
      justify-content: flex-end;
      //margin-bottom: $size-smaller
    }
  }
}

@media only screen and (max-width: 800px) {
  .tableActions {
    .actionButtons {
      margin-bottom: $size-smaller
    }
  }
}

.tableTitle {
  display: flex;
  //flex: 1;
}

.add-new-container {
  cursor: pointer;
  margin: 0px;

  h6 {
    font-weight: 450;
    margin: 0px;
    cursor: pointer;
  }
}

.table-container {
  border-radius: 5px;
  overflow: visible;
  border: 0.5px solid $color-very-light-grey;
  border-top-style: none;

  .tableCell {
    display: flex;
    flex-direction: row;
    align-items: center;

    .tableCellIcon {
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.smallTable {
    .table-responsive {
      overflow: visible !important;
    }

    td {
      vertical-align: middle;
      //padding: 8px;
    }
  }

  .formField {
    padding-bottom: 0 !important;
  }
}

.pagination-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-top: $size-normal;

  p {
    margin: 0px;
  }
}

@media only screen and (max-width: 1000px) {
  .pagination-container {
    flex-direction: column;

    .paginationQuantityBox {
      margin-bottom: $size-normal;
      justify-content: space-between;
      width: 100%;
    }

    p {
      justify-content: flex-end;
    }
  }
}

@media only screen and (max-width: 720px) {
  .pagination-container {
    .paginationQuantityBox {
      flex-direction: column !important;
    }

    .paginationRecordNoBox {
      margin-bottom: $size-normal;
    }
  }
}
